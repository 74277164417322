<template>

  <vehicule-form
    v-if="vehiculeFormData"
    :title="title"
    :is-update="true"
    :vehicule-form-data="vehiculeFormData"
    @submitVehiculeForm="onSubmit"
    @submitVehiculeFormAndPrintEtiquette="onSubmitAndPrint"
    @cancelVehicule="onCancel($event)"
  />

</template>

<script>

import router from '@/router'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import FileManager from '@/utils/FileManager'
import VehiculeForm from './VehiculeForm.vue'

export default {
  components: {
    VehiculeForm,
  },
  data() {
    return {
      title: 'Fiche véhicule',
      vehiculeFormData: undefined,
    }
  },
  computed: {
    isFrontOfficeUser() {
      return !this.$ability.can('manage', 'Vehicule')
    },
  },
  mounted() {
    this.$nextTick(() => {
      const vehiculeId = router.currentRoute.params.id
      let actionName = 'vehicule/fetchFrontOfficeVehicule'
      if (!this.isFrontOfficeUser) {
        actionName = 'vehicule/fetchVehicule'
      }
      store.dispatch(actionName, vehiculeId)
        .then(response => {
          this.vehiculeFormData = response.data
        })
    })
  },
  setup() {
    const toast = useToast()

    const updateVehicule = (actionName, formValue) => store.dispatch(actionName, formValue)
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Erreur lors de la mise à jour du véhicule',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })

    const onSubmit = (actionName, formValue) => updateVehicule(actionName, formValue)
      .then(() => {
        router.back()
      })

    const onSubmitAndPrint = (actionName, formValue) => updateVehicule(actionName, formValue)
      .then(response => store.dispatch('vehicule/fetchEtiquetteByVehicule', response.data.id))
      .then(response => {
        if (response && response.data) { FileManager.forceFileDownload(response.data.filename, response.data.contentType, response.data.body) }
        router.back()
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Impossible d\'imprimer l\'étiquette',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })

    const onCancel = vehiculeId => store.dispatch('vehicule/deleteFrontOfficeVehicule', vehiculeId)
      .then(() => {
        router.push({ name: 'vehicules-stock' })
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Erreur lors de l\'annulation',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })

    return {
      onSubmit,
      onSubmitAndPrint,
      onCancel,
    }
  },
}
</script>
