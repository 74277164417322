<template>
  <b-card
    no-body
    class="mb-0 p-2"
  >

    <h5>
      Sortie du parc
    </h5>

    <b-form-checkbox
      v-model="checkboxValue"
      :disabled="readOnly || isFrontOfficeUser"
    >
      Ce véhicule a quitté le parc
    </b-form-checkbox>

    <!-- Date de facturation -->
    <validation-provider
      v-show="!isFrontOfficeUser"
      #default="validationContext"
      name="Date de facturation"
      :rules="`${!isFrontOfficeUser && checkboxValue ? 'required' : ''}`"
    >
      <b-form-group
        label="Date de facturation"
        label-for="dateFacturation"
      >
        <b-form-datepicker
          id="dateFacturation"
          v-model="vehiculeData.dateFacturation"
          start-weekday="1"
          class="w-100"
          :max="vehiculeData.dateLivraison"
          reset-button
          :disabled="disableInput"
          today-button
          v-bind="labels || {}"
          locale="fr-fr"
          :state="getValidationState(validationContext) ? null : getValidationState(validationContext)"
        />

        <b-form-invalid-feedback :state="getValidationState(validationContext)">
          Le champ Date de facturation est requis
        </b-form-invalid-feedback>
      </b-form-group>
    </validation-provider>

    <!-- Date de livraison -->
    <validation-provider
      ref="dateFactuProvider"
      #default="validationContext"
      name="Date de livraison"
      :rules="{ dateAfter: !isFrontOfficeUser && checkboxValue ? { min: vehiculeData.dateFacturation} : false, required: !isFrontOfficeUser && checkboxValue}"
    >
      <b-form-group
        label="Date de livraison"
        label-for="dateLivraison"
      >
        <b-form-datepicker
          id="dateLivraison"
          v-model="vehiculeData.dateLivraison"
          start-weekday="1"
          class="w-100"
          reset-button
          today-button
          locale="fr"
          v-bind="labels || {}"
          :disabled="disableInput"
          :state="getValidationState(validationContext) ? null : getValidationState(validationContext)"
        />

        <b-form-invalid-feedback :state="getValidationState(validationContext)">
          La date de livraison est requise, et doit être postérieure à la date de facturation
        </b-form-invalid-feedback>
      </b-form-group>
    </validation-provider>

    <!-- Date de préparation -->
    <validation-provider
      v-show="!isFrontOfficeUser"
      #default="validationContext"
      name="Date de préparation"
    >
      <b-form-group
        label="Date de préparation"
        label-for="datePreparation"
      >
        <b-form-datepicker
          id="datePreparation"
          v-model="vehiculeData.datePreparation"
          start-weekday="1"
          class="w-100"
          reset-button
          today-button
          locale="fr"
          v-bind="labels || {}"
          :disabled="disableInput"
          :max="vehiculeData.dateLivraison"
        />

        <b-form-invalid-feedback :state="getValidationState(validationContext)">
          Le champ Date de préparation est requis
        </b-form-invalid-feedback>
      </b-form-group>
    </validation-provider>

    <!-- Site destination -->
    <validation-provider
      #default="validationContext"
      name="Site destination"
      :rules="`${(!isFrontOfficeUser && checkboxValue) || isFrontOfficeUser ? 'required' : ''}`"
    >
      <b-form-group
        label="Site destination"
        label-for="siteDestinationId"
        :state="getValidationState(validationContext)"
      >
        <v-select
          v-model="vehiculeData.siteDestinationId"
          :options="siteDestinationsOptions"
          input-id="siteDestinationId"
          label="nom"
          :reduce="site => site.id"
          :disabled="disableInput"
          class="select-cursor"
        ><div slot="no-options">
          Aucune option
        </div></v-select>

        <b-form-invalid-feedback :state="getValidationState(validationContext)">
          Le champ Site destination est requis
        </b-form-invalid-feedback>
      </b-form-group>
    </validation-provider>

    <!-- Client final -->

    <b-form-group
      label="Client final"
      label-for="clientFinal"
    >
      <b-form-input
        id="clientFinal"
        v-model="vehiculeData.clientFinal"
        autofocus
        trim
        :disabled="disableInput"
      />
    </b-form-group>

    <!-- Immatriculation -->

    <b-form-group
      label="Immatriculation"
      label-for="immatriculation"
    >
      <b-form-input
        id="immatriculation"
        v-model="vehiculeData.immatriculation"
        autofocus
        trim
        :disabled="disableInput"
      />
    </b-form-group>

    <!-- Montant carburant -->
    <b-form-group
      label="Montant carburant"
      label-for="montantCarburant"
    >
      <b-form-input
        id="montantCarburant"
        v-model="vehiculeData.montantCarburant"
        autofocus
        trim
        :disabled="disableInput"
      />
    </b-form-group>

    <!-- N° Car -->
    <b-form-group
      label="N° Car"
      label-for="codeAutoradio"
    >
      <b-form-input
        id="codeAutoradio"
        v-model="vehiculeData.codeAutoradio"
        autofocus
        trim
        :disabled="disableInput"
      />
    </b-form-group>

    <!-- Cédé à -->
    <b-form-group
      label="Cédé à"
      label-for="cedeA"
    >
      <b-form-input
        id="cedeA"
        v-model="vehiculeData.cedeA"
        autofocus
        trim
        :disabled="disableInput"
      />
    </b-form-group>

    <div>
      <span>Autres: </span>
      <!-- Accessoire -->

      <b-form-checkbox
        v-model="vehiculeData.accessoire"
        :disabled="disableInput"
      >
        Accessoire
      </b-form-checkbox>
      <b-form-checkbox
        v-model="vehiculeData.ots"
        :disabled="disableInput"
      >
        OTS effectué
      </b-form-checkbox>
      <b-form-checkbox
        v-model="vehiculeData.gravage"
        :disabled="disableInput"
      >
        Gravage
      </b-form-checkbox>
    </div>

    <!-- Vendeur -->
    <b-form-group
      label="Vendeur"
      label-for="vendeur"
    >
      <b-form-input
        id="vendeur"
        v-model="vehiculeData.vendeur"
        autofocus
        trim
        :disabled="disableInput"
      />
    </b-form-group>

    <!-- Type client -->
    <b-form-group
      label="Type client"
      label-for="clientType"
    >
      <v-select
        v-model="vehiculeData.clientType"
        :options="clientTypeOptions"
        input-id="clientType"
        :reduce="option => option.value"
        :disabled="disableInput"
        class="select-cursor"
      ><div slot="no-options">
        Aucune option
      </div></v-select>
    </b-form-group>

    <!-- Commentaire -->
    <b-form-group
      label="Commentaire"
      label-for="commentaireSortie"
    >
      <b-form-textarea
        id="commentaireSortie"
        v-model="vehiculeData.commentaireSortie"
        autofocus
        rows="3"
        :disabled="disableInput"
      />
    </b-form-group>

  </b-card>
</template>

<script>
import {
  BCard, BFormGroup, BFormInvalidFeedback, BFormDatepicker, BFormCheckbox, BFormTextarea, BFormInput,
} from 'bootstrap-vue'
import { ValidationProvider, extend } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import vSelect from 'vue-select'
import { required } from '@validations'
import datePickerLabels from '@/utils/datePickerLabels'
import moment from 'moment'

export default {
  components: {
    BCard,
    BFormGroup,
    BFormInvalidFeedback,
    BFormDatepicker,
    BFormCheckbox,
    BFormTextarea,
    BFormInput,
    vSelect,

    // Form Validation
    ValidationProvider,
  },
  directives: {
    Ripple,
  },
  props: {
    readOnly: {
      type: Boolean,
      default: false,
    },
    isUpdate: {
      type: Boolean,
      default: false,
    },
    vehiculeData: {
      type: Object,
      default: () => ({}),
    },
    selectedClientId: {
      type: [Number, null],
      default: null,
    },
    isFrontOfficeUser: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      required,
      checkboxValue: this.vehiculeData.sortie,
      siteDestinationsOptions: [],
      labels: datePickerLabels,
      clientTypeOptions: [
        { label: 'Société', value: 'societe' },
        { label: 'Particulier', value: 'particulier' },
        { label: 'Code 10', value: 'code10' },
        { label: 'Show room', value: 'showroom' },
      ],
    }
  },
  computed: {
    disableInput() {
      return this.readOnly || (!this.isFrontOfficeUser && !this.checkboxValue)
    },
  },
  watch: {
    checkboxValue() {
      if (!this.checkboxValue) {
        this.vehiculeData.dateEnvoiAtelier = null
        this.vehiculeData.dateRetourAtelier = null
        this.vehiculeData.reserve = null
        this.vehiculeData.dateFacturation = null
        this.vehiculeData.sortie = false
      } else {
        this.vehiculeData.dateFacturation = moment().format('YYYY-MM-DD')
        this.vehiculeData.sortie = true
        if (this.vehiculeData.dateLivraison === undefined || this.vehiculeData.dateLivraison === null) {
          this.vehiculeData.dateLivraison = this.vehiculeData.dateFacturation
        }
      }
    },
    selectedClientId(newClientId, oldClientId) {
      if (!this.isFrontOfficeUser && newClientId !== null) {
        store.dispatch('site/fetchSites', newClientId)
          .then(response => {
            this.siteDestinationsOptions = response.data.filter(site => site.siteType === 'destination').sort((siteA, siteB) => siteA.nom.localeCompare(siteB.nom))
            if (oldClientId) {
              this.vehiculeData.siteDestinationId = null
            }
          })
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.isFrontOfficeUser) {
        store.dispatch('site/fetchFrontOfficeSites')
          .then(response => {
            this.siteDestinationsOptions = response.data.filter(site => site.siteType === 'destination').sort((siteA, siteB) => siteA.nom.localeCompare(siteB.nom))
          })
      }

      if (!this.isFrontOfficeUser && this.isUpdate) {
        store.dispatch('site/fetchSites', this.vehiculeData.clientId)
          .then(response => {
            this.siteDestinationsOptions = response.data.filter(site => site.siteType === 'destination').sort((siteA, siteB) => siteA.nom.localeCompare(siteB.nom))
          })
      }
    })
  },
  setup() {
    extend('dateAfter', {
      validate(value, args) {
        if (value === null || value === undefined) {
          return false
        }
        return value >= args.min
      },
      params: ['min'],
      message: 'La date de livraison doit être postérieure à la date de facturation',
    })
    const { getValidationState } = formValidation()

    return { getValidationState }
  },
}
</script>
