<template>
  <b-card
    no-body
    class="mb-0 p-2"
  >

    <h5>
      Identité du véhicule
    </h5>

    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <!-- Client -->
        <label>Client</label>

        <v-select
          v-if="!isFrontOfficeUser"
          :value="vehiculeData.clientId"
          class="mb-1 select-cursor"
          :options="clientsOptions"
          :clearable="false"
          input-id="clientId"
          label="nom"
          :reduce="client => client.id"
          :disabled="readOnly"
          @input="(val) => {
            vehiculeData.clientId = val
            $emit('update:selectedClientId', val)
          }"
        ><div slot="no-options">
          Aucune option
        </div></v-select>

        <b-form-input
          v-if="isFrontOfficeUser"
          v-model="vehiculeData.clientNom"
          class="mb-1"
          trim
          :disabled="readOnly"
        />

        <!-- Couleur -->
        <validation-provider
          #default="validationContext"
          name="Couleur"
          rules="required"
        >
          <b-form-group
            label="Couleur"
            label-for="couleur"
          >
            <b-form-input
              id="couleur"
              v-model="vehiculeData.couleur"
              autofocus
              :state="getValidationState(validationContext)"
              trim
              :disabled="readOnly"
            />

            <b-form-invalid-feedback>
              Le champ Couleur est requis
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- Numéro de série -->
        <validation-provider
          #default="validationContext"
          name="Numéro de série"
          rules="required"
        >
          <b-form-group
            label="Numéro de série / Immatriculation"
            label-for="numeroSerie"
          >
            <b-form-input
              id="numeroSerie"
              v-model="vehiculeData.numeroSerie"
              autofocus
              :state="getValidationState(validationContext)"
              trim
              :disabled="readOnly"
            />

            <b-form-invalid-feedback>
              Le champ Numéro de Série est requis
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- Vehicule Type -->
        <validation-provider
          #default="validationContext"
          name="VN / VO"
          rules="required"
        >
          <b-form-group
            label="VN / VO"
            label-for="vehiculeType"
          >

            <b-form-radio-group
              id="vehiculeType"
              v-model="vehiculeData.vehiculeType"
              :options="vehiculeTypesOptions"
              :disabled="readOnly || isUpdate"
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <!-- Marque -->
        <label>Marque</label>
        <v-select
          v-if="!isFrontOfficeUser"
          :value="vehiculeData.marqueId"
          class="mb-1 select-cursor"
          :options="marquesOptions"
          :clearable="false"
          input-id="marqueId"
          label="nom"
          :reduce="marque => marque.id"
          :disabled="readOnly"
          @input="(val) => updateMarque(val)"
        ><div slot="no-options">
          Aucune option
        </div></v-select>

        <b-form-input
          v-if="isFrontOfficeUser"
          v-model="vehiculeData.marqueNom"
          class="mb-1"
          trim
          :disabled="readOnly"
        />

        <validation-provider
          v-if="!isFrontOfficeUser"
          #default="validationContext"
          name="Modèle"
          rules="required"
        >
          <b-form-group
            :state="getValidationState(validationContext)"
          >
            <label>Modèle</label>
            <v-select
              v-model="vehiculeData.modeleId"
              :options="modelesOptions"
              input-id="modeleId"
              label="nom"
              :reduce="modele => modele.id"
              :disabled="readOnly || !selectedMarqueId"
              class="select-cursor"
            ><div slot="no-options">
              Aucune option
            </div></v-select>

            <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <b-form-input
          v-if="isFrontOfficeUser"
          v-model="vehiculeData.modeleNom"
          class="mb-1"
          trim
          :disabled="readOnly"
        />

        <!-- Immatriculation -->
        <b-form-group
          label="Immatriculation"
          label-for="immatriculation"
        >
          <b-form-input
            id="immatriculation"
            v-model="vehiculeData.immatriculation"
            autofocus
            trim
            :disabled="readOnly"
          />
        </b-form-group>

        <!-- Numéro d'ordre -->
        <b-form-group
          label="Numéro d'ordre"
          label-for="numeroOrdre"
        >
          <b-form-input
            id="numeroOrdre"
            v-model="vehiculeData.numeroOrdre"
            autofocus
            trim
            :disabled="readOnly"
          />
        </b-form-group>

        <div class="container" style="margin-top:20px">
          <span>Préparation: </span>
          <b-form-radio-group
            v-model="vehiculeData.preparationStep"
            :options="preparationStepOptions"
            :disabled="readOnly"
          />
          <!-- Relavage -->
          <b-form-checkbox
            v-model="vehiculeData.lustrage"
            :disabled="readOnly"
          >
            Lustrage
          </b-form-checkbox>
          <b-form-checkbox
            v-model="vehiculeData.relavageEffectue"
            :disabled="readOnly"
          >
            Relavage
          </b-form-checkbox>

          <!-- Photos -->

          <b-form-checkbox
            v-model="vehiculeData.photoPrise"
            :disabled="readOnly"
          >
            Photos
          </b-form-checkbox>
          <b-form-checkbox
            v-model="vehiculeData.intersite"
            :disabled="readOnly"
          >
            Intersite
          </b-form-checkbox>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <!-- Commentaire -->

        <b-form-group
          label="Commentaire"
          label-for="commentaire"
        >
          <b-form-textarea
            id="commentaire"
            v-model="vehiculeData.commentaire"
            autofocus
            rows="3"
            :disabled="readOnly"
          />
        </b-form-group>
      </b-col>
    </b-row>

  </b-card>
</template>

<script>
import {
  BCard, BFormGroup, BFormInput, BFormInvalidFeedback, BFormTextarea, BFormRadioGroup, BFormCheckbox, BRow, BCol,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import sortByName from '@/utils/SortListByName'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import vSelect from 'vue-select'

export default {
  components: {
    BCard,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormTextarea,
    BFormRadioGroup,
    BFormCheckbox,
    BRow,
    BCol,
    vSelect,

    // Form Validation
    ValidationProvider,
  },
  directives: {
    Ripple,
  },
  props: {
    readOnly: {
      type: Boolean,
      default: false,
    },
    vehiculeData: {
      type: Object,
      default: () => ({}),
    },
    selectedClientId: {
      type: [Number, null],
      default: null,
    },
    isFrontOfficeUser: {
      type: Boolean,
      default: false,
    },
    isUpdate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      required,
      selectedMarqueId: null,
      marquesOptions: [],
      modelesOptions: [],
      clientsOptions: [],
      preparationStepOptions: [
        { text: 'Aucune', value: 'aucune' },
        { text: 'Demi', value: 'demi' },
        { text: 'Complète', value: 'complete' },
      ],

      vehiculeTypesOptions: [
        { text: 'Véhicule neuf', value: 'neuf' },
        { text: 'Véhicule d\'occasion', value: 'occasion' },
      ],
    }
  },
  watch: {
    selectedMarqueId() {
      if (this.selectedMarqueId !== null) {
        store.dispatch('modele/fetchModeles', this.selectedMarqueId)
          .then(response => {
            this.modelesOptions = sortByName(response.data)
          })
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (!this.isFrontOfficeUser) {
        if (this.selectedClientId !== null && !this.isUpdate) {
          this.vehiculeData.clientId = this.selectedClientId
        }
        store.dispatch('client/fetchClients')
          .then(response => {
            this.clientsOptions = response.data
            if (this.selectedClientId === null && this.clientsOptions.length > 0 && !this.isUpdate) {
              this.vehiculeData.clientId = this.clientsOptions[0].id
              this.$emit('update:selectedClientId', this.clientsOptions[0].id)
            }
          })

        store.dispatch('modele/fetchMarques')
          .then(response => {
            this.marquesOptions = sortByName(response.data)
            if (this.selectedMarqueId === null && this.marquesOptions.length > 0 && !this.isUpdate) {
              this.vehiculeData.marqueId = this.marquesOptions[0].id
              this.selectedMarqueId = this.marquesOptions[0].id
            } else {
              this.selectedMarqueId = this.vehiculeData.marqueId
            }
          })
      }
    })
  },
  methods: {
    updateMarque(value) {
      const newVehiculeData = { ...this.vehiculeData }
      newVehiculeData.marqueId = value
      newVehiculeData.modeleId = null
      this.selectedMarqueId = value
      this.$emit('updateVehiculeData', newVehiculeData)
    },
  },
  setup() {
    const { getValidationState } = formValidation()

    return { getValidationState }
  },
}
</script>
